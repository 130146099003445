var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{class:{
      'sub_text_margin mt-5 d-flex align-content-space-around flex-wrap mb-3': true,
      'mt-5 mb-5': !_vm.$vuetify.breakpoint.smAndUp,
    },attrs:{"elevation":"0"}},[_c('v-card',{staticClass:"pr-4 pt-4",attrs:{"width":"100","elevation":"0"}},[_c('BaseButton',{attrs:{"color":_vm.color,"textColor":_vm.selectedFilter !== 'All' ? _vm.color : 'white',"text":"All","textSize":"caption","outlined":_vm.selectedFilter !== 'All',"width":95},on:{"click":function($event){_vm.selectedFilter = 'All'}}})],1),_vm._l((_vm.filters),function(filter,index){return _c('v-card',{key:index,staticClass:"pr-4 pt-4",attrs:{"width":"100","elevation":"0"}},[_c('BaseButton',{attrs:{"color":_vm.color,"textColor":_vm.selectedFilter !== filter ? _vm.color : 'white',"text":filter,"textSize":"caption","outlined":_vm.selectedFilter !== filter,"width":95},on:{"click":function($event){_vm.selectedFilter = filter}}})],1)})],2),(_vm.selectedFilter !== 'All')?_c('v-text-field',{attrs:{"color":_vm.color,"single-line":"","label":"Type here","filled":""},scopedSlots:_vm._u([(!_vm.$vuetify.breakpoint.smAndUp)?{key:"append-outer",fn:function(){return [_c('HelpNotficationPlusMinusIcon',{attrs:{"color":_vm.color,"type":"plus"},on:{"click":_vm.addStory}})]},proxy:true}:null],null,true),model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}}):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp && _vm.selectedFilter !== 'All')?_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-spacer'),_c('BaseButton',{attrs:{"color":_vm.color,"disabled":!_vm.input,"textColor":"white","text":_vm.selectedStoryToEdit === null ? '+ Add Another' : 'Save'},on:{"click":_vm.addStory}})],1):_vm._e(),_c('div',{staticClass:"stories-list"},[_vm._l((_vm.stories.filter(
        function (story) { return _vm.selectedFilter === 'All' || story.category === _vm.selectedFilter; }
      )),function(item){return _c('div',{key:((item.text) + "-list")},[_c('div',{staticClass:"d-flex flex-row justify-space-between"},[_c('div',{staticClass:"align-self-center my-3 hover-cursor text-truncate",on:{"click":function($event){item.complete = !item.complete;
            _vm.$emit(
              _vm.stories.filter(function (c) { return c.complete; }).length === _vm.stories.length
                ? 'completed'
                : 'pending'
            );}}},[_c('CheckBox',{staticClass:"my-2 mr-3",attrs:{"color":_vm.color,"complete":item.complete}}),_c('span',{staticClass:"item_name"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-chip',{staticClass:"ml-3",attrs:{"color":_vm.color,"x-small":""}},[_c('span',{staticClass:"white--text font-weight-bold"},[_vm._v(" "+_vm._s(item.category)+" ")])])],1),_c('div',{staticClass:"align-self-center edit_delete"},[_c('button',{on:{"click":function($event){_vm.selectedStoryToEdit = item;
              _vm.input = item.text;}}},[_vm._v(" Edit ")]),_c('span',{staticClass:"mx-1"},[_vm._v("|")]),_c('button',{on:{"click":function($event){_vm.stories = _vm.stories.filter(function (ele) { return ele.id !== item.id; })}}},[_vm._v(" Delete ")]),_c('v-tooltip',{attrs:{"bottom":"","color":_vm.color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({attrs:{"icon":"","fab":""},on:{"click":function($event){_vm.showModal = true}}},'button',attrs,false),on),[_c('v-icon',{staticClass:"ml-2",attrs:{"color":_vm.color}},[_vm._v("mdi-share-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Do you want to share this story with someone?")])])],1)]),_c('v-divider',{attrs:{"color":_vm.color}})],1)}),(
        _vm.selectedFilter !== 'All' &&
        _vm.stories.filter(function (story) { return story.category === _vm.selectedFilter; })
          .length === 0
      )?_c('v-card-text',[_vm._v("No story available in this category!")]):_vm._e()],2),_c('BaseModal',{attrs:{"showModal":_vm.showModal,"showToolBar":false,"buttonText":"Share","toolbarTextColor":"white","toolbarColor":_vm.color,"btnColor":_vm.color,"width":"600px"},on:{"update:showModal":function($event){_vm.showModal=$event},"update:show-modal":function($event){_vm.showModal=$event}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"pt-6"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-text-field',{staticClass:"mx-7 mt-3",attrs:{"color":_vm.color,"single-line":"","label":"Name","filled":""}})],1),_c('v-col',[_c('v-text-field',{staticClass:"mx-7 mt-3",attrs:{"color":_vm.color,"single-line":"","label":"Email","filled":""}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-text-field',{staticClass:"mx-7 mt-3",attrs:{"color":_vm.color,"single-line":"","label":"Your Message...","filled":""}})],1)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }