import { render, staticRenderFns } from "./FarewellToneTheme.vue?vue&type=template&id=667c33e8&scoped=true&"
import script from "./FarewellToneTheme.vue?vue&type=script&lang=js&"
export * from "./FarewellToneTheme.vue?vue&type=script&lang=js&"
import style0 from "./FarewellToneTheme.vue?vue&type=style&index=0&id=667c33e8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "667c33e8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VChip,VColorPicker,VFadeTransition,VRow,VSnackbar,VSpacer})
