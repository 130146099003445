var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{class:{
      'mt-5': true,
      'mt-5 mb-5': !_vm.$vuetify.breakpoint.smAndUp,
    },attrs:{"elevation":"0"}},_vm._l((_vm.priorities),function(choice,index){return _c('v-row',{key:index},[_c('v-col',[_c('BaseButton',{attrs:{"color":_vm.color,"textColor":!choice.chosen ? _vm.color : 'white',"text":choice.text,"outlined":!choice.chosen,"width":"100%"},on:{"click":function($event){return _vm.choose(index)}}})],1),_c('v-col',[_c('v-fade-transition',[_c('TheSlider',{directives:[{name:"show",rawName:"v-show",value:(choice.chosen),expression:"choice.chosen"}],attrs:{"value":choice.priority,"color":_vm.color,"trackColor":_vm.color,"label":"Priority","step":1,"showValue":false,"tickSize":3,"showTicks":"always","maxValue":5,"thumbLabel":true,"minValue":1}})],1)],1)],1)}),1),_c('div',{staticClass:"mt-9 subText"},[_vm._v(" Have any arrangements been made for the above or anything you would like to explain? ")]),_c('TextArea',{staticClass:"mt-3",attrs:{"value":_vm.answer,"size":"small","disabled":!_vm.editMode},on:{"update:value":function($event){_vm.answer=$event}}}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-spacer'),_c('v-fade-transition',[_c('BaseButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.editMode),expression:"editMode"}],staticClass:"mr-2",attrs:{"color":"grey_dark","textColor":"grey_dark","text":"Cancel","outlined":true},on:{"click":function($event){_vm.editMode = false;
          _vm.answer = _vm.value;}}})],1),(_vm.editMode)?_c('BaseButton',{attrs:{"color":_vm.color,"textColor":"white","text":"Save"},on:{"click":function($event){_vm.editMode = false;
        _vm.answer.length > 0 && _vm.$emit('completed');}}}):_c('BaseButton',{staticClass:"ml-2",attrs:{"color":_vm.color,"textColor":"white","text":"Edit"},on:{"click":function($event){_vm.editMode = true}}})],1),_c('v-snackbar',{attrs:{"color":_vm.color,"rounded":"pill","outlined":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":_vm.color,"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},'v-btn',attrs,false),[_vm._v(" Ok ")])]}}]),model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_vm._v(" "+_vm._s(_vm.snackBarText)+" "),_c('v-spacer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }