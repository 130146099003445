var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-12"},[_c('ProgressDots',{staticClass:"mb-4",attrs:{"totalSteps":_vm.choices.length,"completedSteps":_vm.selectedTab,"color":_vm.color}}),_c('ProgressCard',{class:{
      'mr-7 mb-6': !_vm.$vuetify.breakpoint.sm && !_vm.$vuetify.breakpoint.xs,
      'mr-9': _vm.$vuetify.breakpoint.sm,
      'pa-2 my-5': true,
    },attrs:{"color":_vm.color,"icon":_vm.icon,"title":_vm.title,"completedSteps":_vm.completedSteps,"state":"active","totalSteps":_vm.totalSteps}}),_c('v-card',{staticClass:"pa-7 pt-8",attrs:{"flat":"","rounded":"lg"}},[_c('div',{staticClass:"main_text"},[_vm._v(" "+_vm._s(_vm.choices.find(function (ele) { return ele.id === _vm.selectedTab; }).text)+" ")]),_c('span',{staticClass:"sub_text"},[_c('GuideText',{attrs:{"textList":_vm.choices.find(function (ele) { return ele.id === _vm.selectedTab; }).guideText}})],1)]),_c('v-card',{staticClass:"my-3 py-6",attrs:{"flat":"","rounded":"lg","min-height":_vm.$vuetify.breakpoint.smAndUp ? 570 : 450}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"main_text"},[_vm._v(" "+_vm._s(_vm.choices.find(function (ele) { return ele.id === _vm.selectedTab; }).text)+" ")]):_vm._e(),_c('div',{class:{
        'sub_text sub_text_margin': true,
        'mt-2 mb-5': !_vm.$vuetify.breakpoint.smAndUp,
      }},[_vm._v(" "+_vm._s(_vm.choices.find(function (ele) { return ele.id === _vm.selectedTab; }).question)+" ")]),_c(_vm.choices.find(function (ele) { return ele.id === _vm.selectedTab; }).component,{tag:"component",class:_vm.choices.find(function (ele) { return ele.id === _vm.selectedTab; }).classes,attrs:{"color":_vm.color},on:{"completed":function($event){_vm.choices.find(function (ele) { return ele.id === _vm.selectedTab; }).complete = true},"pending":function($event){_vm.choices.find(function (ele) { return ele.id === _vm.selectedTab; }).complete = false}}})],1),_c('v-fade-transition',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTab !== 1),expression:"selectedTab !== 1"}],staticClass:"mid-left ml-2",attrs:{"fab":"","small":"","color":_vm.color},on:{"click":function($event){_vm.selectedTab =
          _vm.selectedTab === 1
            ? _vm.choices.length
            : (_vm.selectedTab - 1) % (_vm.choices.length + 1)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-left")])],1)],1),_c('v-fade-transition',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTab !== _vm.choices.length),expression:"selectedTab !== choices.length"}],staticClass:"mid-right",attrs:{"fab":"","small":"","color":_vm.color},on:{"click":function($event){_vm.selectedTab =
          _vm.selectedTab === _vm.choices.length
            ? 1
            : (_vm.selectedTab + 1) % (_vm.choices.length + 1)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-right")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }